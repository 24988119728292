import { render, staticRenderFns } from "./CreateProjectEvent.vue?vue&type=template&id=3191b72b&scoped=true"
import script from "./CreateProjectEvent.vue?vue&type=script&lang=js"
export * from "./CreateProjectEvent.vue?vue&type=script&lang=js"
import style0 from "./CreateProjectEvent.vue?vue&type=style&index=0&id=3191b72b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3191b72b",
  null
  
)

export default component.exports